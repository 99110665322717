import {
  category,
  green,
  orange1,
  orange2,
  black,
  white,
} from "./category.module.scss"
import React from "react"

function Category(props) {
  let style
  switch (props.color) {
    case "green":
      style = green
      break
    case "orange1":
      style = orange1
      break
    case "orange2":
      style = orange2
      break
    case "white":
      style = white
      break
    case "black":
      style = black
      break
    default:
      style = green
  }
  return <h2 className={category + " " + style}>{props.children}</h2>
}

export default Category
